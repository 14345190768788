<template>
  <div class="search">
    <ShopNavBar title="搜索" />

    <div class="header">
      <div class="input-wrap">
        <q-input rounded borderless v-model="search" dense placeholder="请输入搜索关键词" @keyup.enter="onSearch">
          <template v-slot:prepend>
            <q-icon name="search" size="xs" />
          </template>
        </q-input>
      </div>
    </div>

    <div class="goods-wrap">
      <van-list v-if="goodslist.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        finished-text="没有更多了" @load="onLoad">
        <GoodsList :list="goodslist" />
      </van-list>
      <van-empty description="暂无商品" v-if="goodslist.length == 0" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { List, Empty } from 'vant'
import GoodsList from './components/GoodsList.vue'

export default {
  components: {
    [List.name]: List,
    [Empty.name]: Empty,
    GoodsList
  },
  setup() {
    return {
      search: ref(""),
      goodsname: ref(""),
      goodstype: ref(99),
      xlid: ref(0),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      goodslist: ref([])
    }
  },
  created() {
    let query = this.$route.query
    if(query.name) {
      this.goodsname = query.name
    }
    if(query.goodstype) {
      this.goodstype = query.goodstype
    }
    if(query.xlid) {
      this.xlid = query.xlid
    }
    this.getdata()
  },
  methods: {
    onSearch() {
      this.goodsname = this.search
      this.pageIndex = 1
      this.loading = false
      this.finished = false
      this.goodslist = []
      this.getdata()
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/Search",
        {
          goodstype: _this.goodstype,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          query: _this.goodsname,
          xlid: _this.xlid
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.img = item.dbShopGoodsModel[0].img
              item.price = item.dbShopGoodsModel[0].price
            })
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.goodslist.push(...data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.search {
  padding-top: 64px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.header {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  z-index: 9;
}

.input-wrap {
  margin: 0 15px;
  flex: 1;
  padding: 0 10px;
  border-radius: 99px;
  background-color: rgba(242, 242, 242, 1);
}

.input-wrap :deep(.q-field--dense .q-field__control) {
  height: 35px;
}

.input-wrap :deep(.q-field--dense .q-field__marginal) {
  height: 35px;
}
</style>